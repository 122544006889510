var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-with-container',{staticClass:"services py-0"},[_c('v-row',{staticClass:"d-none d-md-flex"},_vm._l((_vm.services),function(ref){
var imageSrc = ref.imageSrc;
var title = ref.title;
var description = ref.description;
var service = ref.service;
var target = ref.target;
return _c('v-col',{key:title,attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('div',[_c('img',{staticClass:"image",attrs:{"src":imageSrc,"width":"280","height":"200"}})]),_c('div',{staticClass:"pa-4"},[_c('h4',{staticClass:"secondary--text text-center"},[_vm._v(_vm._s(title))]),_c('p',{staticClass:"text-center mb-5 px-lg-8 description-section"},[_vm._v(_vm._s(description))]),_c('div',{staticClass:"text-center my-4"},[_c('v-btn',{attrs:{"large":"","color":"primary","min-width":"200","href":target},on:{"click":function($event){return _vm.shopQualityVerifiedCars(title)}}},[_c('span',[_vm._v(_vm._s(service))])])],1)])])],1)}),1),_c('v-row',{staticClass:"d-md-none",attrs:{"no-gutters":""}},[_c('v-expansion-panels',_vm._l((_vm.services),function(ref){
var imageSrc = ref.imageSrc;
var title = ref.title;
var description = ref.description;
var service = ref.service;
var target = ref.target;
return _c('v-expansion-panel',{key:title,staticClass:"my-2"},[_c('v-expansion-panel-header',[_c('h5',{staticClass:"secondary--text"},[_vm._v(_vm._s(title))])]),_c('v-expansion-panel-content',[_c('p',[_vm._v(_vm._s(description))]),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"large":"","color":"primary","href":target}},[_c('span',[_vm._v(_vm._s(service))])])],1)])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }