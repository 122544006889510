import { Component, Prop, Vue } from 'vue-property-decorator';
import SearchBox from '@/features/home-page/buying-easy/search-box/SearchBox';

@Component({
  name: 'buying-easy',
  components: { SearchBox },
})
export default class extends Vue {
  @Prop() inventoryCount!: number;
}
