import { Component, Vue } from 'vue-property-decorator';
import { SoldInventory } from '../../../../../shared/inventory';
import { LocationDetails } from '../../../../../shared/inventory/search';
import { inventoryApi } from '../api';
import { FilterModule } from '@/store/filter';
import { storageWrapper } from '@/tools/storage';

const initialTimeout = 4; // seconds
const repeatInterval = 5; // seconds

@Component
export default class SoldCars extends Vue {
  @FilterModule.State public LocationOptions!: LocationDetails[];
  public displayMode: number = 0;

  private timeout?: number;
  private soldCars: SoldInventory[] | undefined[] = [];
  private index: number = -1;

  private get Inventory(): SoldInventory | undefined {
    return this.soldCars[this.index];
  }

  public get Classes(): string {
    let result = '';
    if (this.displayMode > 0) {
      result += 'is-visible ';
    }
    if (this.displayMode > 1) {
      result += 'is-dismissed ';
    }

    return result.trimEnd();
  }

  public get State(): string | undefined {
    if (!this.Inventory) {
      return;
    }

    const locationCode = this.Inventory.Search.LocationCode;
    const location = this.LocationOptions?.find((x) => x.Key === locationCode);
    const state = location?.Address?.State;
    if (!state) {
      return;
    }
    return ` in ${state}`;
  }

  public get CarDescription(): string | undefined {
    const vehicleDetails = this.Inventory?.GlassVehicle?.VehicleDetails;
    if (!vehicleDetails) {
      return;
    }

    return `${vehicleDetails.ReleaseYear} ${vehicleDetails.Make} ${vehicleDetails.Model}`;
  }

  public Hide() {
    this.displayMode = 2;
    clearTimeout(this.timeout);
    storageWrapper.Write('SoldCarsLastDismissedDate', this.getDate());
  }

  public async mounted() {
    const closedDate = storageWrapper.Read<number>('SoldCarsLastDismissedDate');
    if (closedDate !== this.getDate()) {
      try {
        await this.GetSoldCars();
        if (this.soldCars.length > 0) {
          const self = this;
          setTimeout(() => {
            self.displayMode = 1;
            self.UpdateIndex();
          }, initialTimeout * 1000);
        }
      } catch (error) {
        this.$sentry.captureException(error);
      }
    }
  }

  public beforeDestroy() {
    this.displayMode = 2;
    clearTimeout(this.timeout);
  }

  private async GetSoldCars() {
    try {
      const response = await inventoryApi.GetSoldInventory();
      if (response) {
        this.soldCars = response;
      }
    } catch (error) {
      this.$sentry.captureException(error);
    }
  }

  private getDate(): number {
    const date = new Date();
    return date.setHours(0, 0, 0, 0);
  }

  private UpdateIndex() {
    if (this.displayMode === 1) {
      this.index++;
      if (this.index >= this.soldCars.length) {
        this.index = 0;
      }

      setTimeout(this.UpdateIndex, repeatInterval * 1000);
    }
  }
}
