import { Component, Vue } from 'vue-property-decorator';
import { Context } from '@nuxt/types';
import { Jsonld } from 'nuxt-jsonld';
import { TagToolNavigateEvent, dataLayerAdd } from '@/helpers/analytics-helper';
import { RouteNames } from '@/router/route-consts';
import { FilterModule } from '@/store/filter';
import { PromoModule } from '@/store/promo';
import { HeaderModule } from '@/store/header';
import ShopByType from '@/features/home-page/ShopByType';
import PopularCars from '@/features/home-page/PopularCars';
import Promo from '@/features/promo/Promo.vue';
import { NavOptions } from '@/features/header/types';
import { SortOptions } from '@/features/filter/types';
import { VehicleType } from '~/../shared/inventory/search';
import DEFAULT_JSON_LDS from '@/utils/defaultJsonLD';
import BuyingEasy from '@/features/home-page/buying-easy/BuyingEasy';
import Services from '@/features/home-page/services/Services';
import LocateUs from '@/features/home-page/locate-us/LocateUs';
import FrequentlyAskedQuestion from '@/features/home-page/frequently-asked-question/FrequentlyAskedQuestion';
import BestPriceGuarantee from '@/features/buy/vehicle-details/BestPriceGuarantee/BestPriceGuarantee.vue';
import QualityVerifiedCars from '@/features/home-page/quality-verified-cars/QualityVerifiedCars';
import BuyWithConfidence from '@/features/home-page/buy-with-confidence/BuyWithConfidence';
import SoldCars from '@/features/inventory/sold-cars/SoldCars';
import ShopByLocation from '@/features/home-page/ShopByLocation';
import { inventoryApi } from '@/features/inventory/api';
import { getPageMetaData, pageMetaDataApi } from '@/features/api';
import { PageMetaData } from '~/../shared/global/types';

@Jsonld
@Component({
  components: {
    BuyingEasy,
    ShopByType,
    ShopByLocation,
    PopularCars,
    Services,
    LocateUs,
    FrequentlyAskedQuestion,
    BestPriceGuarantee,
    QualityVerifiedCars,
    BuyWithConfidence,
    SoldCars,
  },

  async asyncData({ route }: Context) {
    try {
      const selectionsWithoutFilter = {
        Search: {},
        Sort: {
          Featured: true,
        },
      };

      const inventory = await inventoryApi.FindInventoryItemsDebounced(
        selectionsWithoutFilter
      );

      const pageMetaData = await pageMetaDataApi.loadPageMetaDataWithFallback(
        route.path
      );

      if (inventory) {
        const inventoryCount = inventory.length;
        return { inventoryCount, pageMetaData };
      }
      return { pageMetaData };
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
      this.$sentry?.captureException(error);
      throw error;
    }
  },
  head() {
    return (this as any).head;
  },
})
export default class Home extends Vue {
  public ScrollBehaviour = {};
  public inventoryCount!: number;
  public pageMetaData?: PageMetaData;

  @FilterModule.Mutation public ClearFilters!: () => void;
  @FilterModule.Mutation public ToggleFilter!: (key: string) => void;
  @FilterModule.State public vehicleTypes!: VehicleType[];
  @PromoModule.State public PromoOptions!: (string | Promo)[];
  @HeaderModule.State private NavOptions!: NavOptions;
  @FilterModule.State private SortOptions!: SortOptions;
  @FilterModule.Mutation private UpdateSortOptions!: (x: string) => void;

  jsonld() {
    return [...DEFAULT_JSON_LDS];
  }

  public get head() {
    return getPageMetaData(this.pageMetaData, this.$route.path);
  }

  public ShowCarsNearYou() {
    this.ClearFilters();
    if (this.SortOptions.Selected !== 1) {
      this.UpdateSortOptions('Nearest Distance');
    }
    this.$router.push({ name: RouteNames.SearchRouteName });
  }

  public ShowLocation() {
    this.NavOptions.drawer = false;
    this.NavOptions.location = true;
    this.NavOptions.modalLocation = false;

    // TODO: move this to a GA service.
    const toolData: TagToolNavigateEvent = {
      event: 'toolNavigate',
      toolEvent: 'start',
      toolName: 'setLocation',
    };

    dataLayerAdd(toolData);
  }

  public get mainVehicleTypes() {
    return this.vehicleTypes.filter((item) => item.isMain);
  }

  public get hasItems(): boolean {
    return this.mainVehicleTypes.length !== 0;
  }

  private performScroll(goForward: boolean, el: Element | null) {
    if (!el) {
      return;
    }

    this.ScrollBehaviour = { 'scroll-behavior': 'smooth' };

    this.$nextTick(() => {
      el.scrollBy(el.clientWidth * 0.9 * (goForward ? 1 : -1), 0);
      this.$nextTick(() => {
        this.ScrollBehaviour = {};
      });
    });
  }

  // eslint-disable-next-line camelcase
  public scroll_left(id: string) {
    const content = document.querySelector(`#${id}`);
    this.performScroll(false, content);
  }

  // eslint-disable-next-line camelcase
  public scroll_right(id: string) {
    const content = document.querySelector(`#${id}`);
    this.performScroll(true, content);
  }
}
