import { Component, Vue } from 'vue-property-decorator';
import Faqs from '@/components/Faqs/Faqs';

@Component({
  name: 'frequently-asked-question',
  components: {
    Faqs,
  },
  data: () => ({
    faqs: [
      {
        title: 'Do you only sell your used cars online?',
        description: [
          'No, we have used car <a href="/locations" target="_blank" class="error--text">locations</a> near Perth, Adelaide, Melbourne, Sydney, Brisbane, and Hobart, however you can also see, buy, and finance our entire range online.',
        ],
      },
      {
        title: 'What’s the quickest way to finance my used car?',
        description: [
          'You could get approved for finance in just minutes with our 100% online finance application. It’s that easy. Simply find a car you love and select “FINANCE NOW” to start your application on an eligible car anytime.',
        ],
      },
      {
        title:
          'What’s the best way to reserve or place a hold on a car I’m interested in?',
        description: [
          'If you find a car you don’t want to miss out on, you can select the “RESERVE NOW” button, fill in some basic details, place a fully refundable $500 deposit and reserve it before someone else does. You can also purchase a car entirely online, including an offer to purchase.',
        ],
      },
      {
        title: 'Do you have used cars for sale under $10,000?',
        description: [
          'Yes, we usually have a large range of cars available for under $10,000 <a href="/buy/used-cars/under-10000" target="_blank" class="error--text">here</a>. Be sure to filter by your nearest location.',
        ],
      },
      {
        title: 'Do you buy used cars as well?',
        description: [
          'Yes, we’ll trade in any used car you have if you purchase one of our cars. We’ll also give you an obligation free valuation on any car you would like to sell, even if you aren’t buying a car from us.',
        ],
      },
      {
        title:
          'How is easyauto123 different from a regular used car dealership?',
        description: [
          'We’re all about making it easy. We give our customers the option to buy, sell, and finance used cars completely online, but if you want to visit one of our <a href="/locations" target="_blank" class="error--text">locations</a>  so you can view and test-drive a car, that’s okay too. We understand everyone is different and we’ll help you buy your car, your way. Our cars are fixed priced, so you don’t need to haggle, and we back it all up with our <a href="/terms-of-use/easyauto123_BestCarPriceGuarantee_Terms.pdf" target="_blank" class="error--text">Best Car Price Guarantee</a> and <a href="/terms-of-use/ea123--7day%20terms-v5.pdf" target="_blank" class="error--text">7-day Money Back Guarantee</a>.',
        ],
      },
    ],
  }),
})
export default class extends Vue {}
