import { Component, Prop, Vue } from 'vue-property-decorator';
import { Makes } from '~/../shared/inventory/search';
import { FilterModule } from '@/store/filter';
import { InventoryModule } from '@/store/inventoryStore';
import { LocationModule } from '@/store/location';
import {
  ModelOptions,
  Selections,
  SortOptions,
  SearchHints,
} from '@/features/filter/types';
import { inventoryApi } from '@/features/inventory/api';
import { CreateFilters } from '@/features/inventory/filterBuilders/CreateFilters';
import { EnquiriesModule } from '@/store/enquiries';
import LocationDetails from '~/src/features/location/Locations/LocationDetails/LocationDetails';
import { RouteNames } from '@/router/route-consts';
import { EnquiryData } from '~/../shared/enquiries';

@Component({
  name: 'search-box',
  data: () => ({
    States: [
      'New South Wales',
      'Queensland',
      'South Australia',
      'Tasmania',
      'Victoria',
      'Western Australia',
    ],
  }),
})
export default class extends Vue {
  @Prop() inventoryCount!: number;

  @FilterModule.State public MakeOptions!: Makes;
  @FilterModule.Mutation private ToggleFilter!: (key: string) => void;
  @FilterModule.Mutation private ClearFilters!: () => void;
  @FilterModule.State public ModelOptions!: ModelOptions;
  @InventoryModule.State public SearchResult!: string[];
  @LocationModule.Getter private selectedLocation: LocationDetails | undefined;
  @FilterModule.State private SortOptions!: SortOptions;

  @EnquiriesModule.State private EnquiryData!: EnquiryData;
  @FilterModule.State private SearchHints!: SearchHints[];
  @FilterModule.State private StockNumberSearchHints!: SearchHints[];
  @FilterModule.State private RegistrationSearchHints!: SearchHints[];

  public selectedMake: string | undefined = '';
  public selectedModel: string | undefined = '';
  public selectedState: string | undefined = '';

  public loading: boolean = false;
  public modelList: string[] = [];
  public filteredInventoryCount: number | null = null;

  public sellChoices: boolean = false;
  public appraisalType: string = '';

  public get make(): string {
    return this.selectedMake || '';
  }

  public set make(value: string) {
    this.loading = true;
    this.selectedModel = '';
    this.selectedMake = value;

    this.modelList = this.ModelOptions.Makes[value]?.map(
      (model) => model.Name || ''
    );

    const filter = [`Make.${value}`];

    this.getFilteredInventory(filter);
  }

  public get model(): string {
    return this.selectedModel || '';
  }

  public set model(value: string) {
    this.loading = true;
    this.selectedModel = value;

    const filter = [
      `Make.${this.selectedMake}`,
      `Model.${this.selectedMake}.${value}`,
    ];

    this.getFilteredInventory(filter);
  }

  public async getFilteredInventory(filter: string[]) {
    const selections = {
      filters: filter,
      preferredLocation: this.selectedLocation,
      sort: this.SortOptions,
    } as Selections;

    const filters = CreateFilters(selections);

    const filteredInventory = await inventoryApi.FindInventoryItemsDebounced(
      filters
    );

    this.filteredInventoryCount = filteredInventory.length;

    this.loading = false;
  }

  public onClickShopUsedCars() {
    this.ClearFilters();

    if (!this.selectedMake) {
      this.$router.push({ name: RouteNames.SearchRouteName });
      return;
    }

    this.selectedModel
      ? this.ToggleFilter(`Model.${this.selectedMake}.${this.selectedModel}`)
      : this.ToggleFilter(`Make.${this.selectedMake}`);

    this.$router.push({ name: RouteNames.SearchRouteName });
  }

  public showSellChoices() {
    this.appraisalType = '';
    this.sellChoices = true;
  }

  public openEasyquote() {
    window.open(`${this.$config.easyquoteurl}`, '_blank');
  }

  public get isSelectModelDisabled(): boolean {
    return this.selectedModel
      ? !this.selectedModel
      : !this.selectedMake || this.loading || this.filteredInventoryCount === 0;
  }

  public get isSearchUsedCarsCtaDisabled(): boolean {
    return this.loading || this.filteredInventoryCount === 0;
  }

  public get searchUsedCarsCta(): string {
    if (!this.make) {
      return `Search All ${this.inventoryCount} USED CARS`;
    }
    if (this.filteredInventoryCount === 0) {
      return 'SORRY, NO CARS MATCH';
    }

    if (this.filteredInventoryCount === 1) {
      return `SELECT FROM ${this.filteredInventoryCount} USED CAR`;
    }
    return `SELECT FROM ${this.filteredInventoryCount} USED CARS`;
  }

  public startSell() {
    if (this.appraisalType === 'eaAppraisal') {
      this.$router.push({ name: 'sellenquire' });
      this.sellChoices = false;
    }
    if (this.appraisalType === 'easyQuote') {
      this.openEasyquote();
      this.sellChoices = false;
    }
  }
}
