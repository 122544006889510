import { Component, Vue } from 'vue-property-decorator';
import { groupBy } from 'lodash';
import { FilterModule } from '@/store/filter';
import { LocationDetails } from '~/../shared/inventory/search';

@Component({
  name: 'shop-by-location',
})
export default class extends Vue {
  @FilterModule.State public LocationOptions!: LocationDetails[];

  public get groupLocationByCity() {
    return Object.entries(groupBy(this.LocationOptions, 'Address.City'))
      .map(([key, value]) => ({
        city: key,
        state: value[0].Address?.StateShort || '',
      }))
      .sort(
        (a, b) =>
          a.state?.localeCompare(b.state) || a.city.localeCompare(b.city)
      );
  }

  public searchLocationTarget(city: string, state: string) {
    return `/buy/used-cars/city-${city?.toLowerCase()}/state-${state?.toLowerCase()}`;
  }
}
