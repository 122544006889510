import { Component, Vue } from 'vue-property-decorator';
import { FilterModule } from '@/store/filter';
import { RouteNames } from '@/router/route-consts';

@Component({
  name: 'services',
  data: () => ({
    services: [
      {
        imageSrc: '/img/home-page/highest-quality-used-cars.svg',
        title: 'Highest quality cars',
        description: 'Australia’s largest range of Quality Verified used cars.',
        service: 'FIND A CAR',
        target: '/buy/used-cars',
      },
      {
        imageSrc: '/img/home-page/pre-qualified-finance.svg',
        title: 'Fast, flexible finance',
        description:
          'Get finance for your dream car in minutes – fully approved.',
        service: 'get finance',
        target: '/finance',
      },
      {
        imageSrc: '/img/home-page/best-car-guarantee.svg',
        title: 'Online or on the lot',
        description:
          'Visit one of our nationwide stores or shop 100% online. You choose.',
        service: 'learn more',
        target: '/buy-used-cars-online',
      },
    ],
  }),
})
export default class extends Vue {
  @FilterModule.Mutation public ClearFilters!: () => void;
  @FilterModule.Mutation public ToggleFilter!: (key: string) => void;

  public shopQualityVerifiedCars(value: string) {
    if (value !== 'Highest quality cars') {
      return;
    }
    this.ClearFilters();
    this.ToggleFilter('ProdCateg.Quality Verified');
    this.$router.push({ name: RouteNames.SearchRouteName });
  }
}
