import { Component, Vue } from 'vue-property-decorator';
import { FilterModule } from '@/store/filter';
import { RouteNames } from '@/router/route-consts';

@Component({
  name: 'quality-verified-cars',
})
export default class extends Vue {
  @FilterModule.Mutation public ClearFilters!: () => void;
  @FilterModule.Mutation public ToggleFilter!: (key: string) => void;

  public shopQualityVerifiedCars() {
    this.ClearFilters();
    this.ToggleFilter('ProdCateg.Quality Verified');
    this.$router.push({ name: RouteNames.SearchRouteName });
  }
}
