import { render, staticRenderFns } from "./Services.vue?vue&type=template&id=fd63cc84&scoped=true&lang=pug&"
import script from "./Services.ts?vue&type=script&lang=js&"
export * from "./Services.ts?vue&type=script&lang=js&"
import style0 from "./Services.vue?vue&type=style&index=0&id=fd63cc84&lang=scss&scoped=true&"
import style1 from "./Services.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd63cc84",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionWithContainer: require('/opt/atlassian/pipelines/agent/build/website/src/components/section-with-container/SectionWithContainer.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VRow})
