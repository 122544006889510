const DEFAULT_JSON_LDS = [
  {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: 'https://www.easyauto123.com.au/',
  },
  {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'easyauto123 - the best way to buy and sell used cars',
    url: 'https://www.easyauto123.com.au',
    logo: 'https://www.easyauto123.com.au/img/easyauto123-logo.svg',
    sameAs: [
      'https://www.facebook.com/easyauto123/',
      'https://instagram.com/easyauto123_au/',
      'https://www.youtube.com/channel/UCpn--j_kYytibvlPVMSlJTw',
    ],
  },
];
export default DEFAULT_JSON_LDS;
