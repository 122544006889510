import { Component, Vue } from 'vue-property-decorator';
import SectionWithContainer from '@/components/section-with-container/SectionWithContainer';
@Component({
  name: 'best-price-guarantee',
  components: {
    SectionWithContainer,
  },
  data: () => ({
    title: 'The Best Car Price, That’s our Guarantee',
    image: '/img/promo/best-price-guarantee.webp',
    image2: '/img/promo/best-car-guarantee.webp',
    text: 'Wouldn’t it be great if you didn’t have to argue the price of your car and just got the best price upfront? Yeah, we think so too.',
    text2:
      'And if we get it wrong – no worries, our <a href="/terms-of-use/easyauto123_BestCarPriceGuarantee_Terms.pdf" class="secondary--text" target="_blank">Best Car Price Guarantee*</a> is there to make sure we make it right.',
    pdfLinkText: '*Best Car Price Guarantee terms and conditions (PDF)',
    pdfLink: '/terms-of-use/easyauto123_BestCarPriceGuarantee_Terms.pdf',
  }),
})
export default class extends Vue {}
