import { render, staticRenderFns } from "./BuyWithConfidence.vue?vue&type=template&id=1f3be6ac&scoped=true&lang=pug&"
import script from "./BuyWithConfidence.ts?vue&type=script&lang=js&"
export * from "./BuyWithConfidence.ts?vue&type=script&lang=js&"
import style0 from "./BuyWithConfidence.vue?vue&type=style&index=0&id=1f3be6ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f3be6ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionWithContainer: require('/opt/atlassian/pipelines/agent/build/website/src/components/section-with-container/SectionWithContainer.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VRow})
